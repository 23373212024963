import { Suspense, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RestAPI } from "@/utils/api-rest";

import ErrorBoundary from "./components/Utils/ErrorBoundary";
import FarmProvider from "./contexts/FarmContext";
import TypesProvider from "./contexts/TranslatedTypes.js";
import withAuthenticator from "./hoc/withAuthenticator";
import { FarmContext } from "./contexts/FarmContext";
import Menubar from "./components/Menubar/Menubar";
import Header from "./components/Header/Header";
import Login from "./pages/Login/Login";
import PolicyAndTerms from "./pages/PolicyAndTerms/PolicyAndTerms";
import LoadingDialog from "../src/components/Utils/LoadingDialog";
import BillingMenubar from "./components/BillingMenuBar/BillingMenubar";
import { ToastContainer } from "react-toastify";

import "primeicons/primeicons.css";
import "./App.css";
import "./scss/styles.scss";
import "primereact/resources/themes/lara-light-blue/theme.css";
//import 'primeflex/primeflex.css'

import TagManager from "react-gtm-module";
import GATracker from "./components/GATracker/GATracker";

import { AuthenticatorContext } from "./contexts/AuthenticatorContext";

import {
    Outlet, useMatch, useRouteLoaderData, useNavigation, useRevalidator, redirect, replace,
    UNSAFE_createBrowserHistory, UNSAFE_mapRouteProperties, UNSAFE_createRouter,
} from "react-router";
import { RouterProvider } from "react-router/dom";

import { putLog } from "./utils/logcache";
import { ErrorElement } from "./components/Utils/ErrorBoundaryRoute";

const TAG_MANAGER_ID = import.meta.env.VITE_GTM_CONTAINER_ID;
//ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
    gtmId: TAG_MANAGER_ID
};
TagManager.initialize(tagManagerArgs);

const dynamicRoutes = [
    {
        id: "general",
        parent: "with-tos",
        getChildren: () => import("./routes/general.js")
    },
    {
        id: "billing",
        parent: "with-tos",
        getChildren: () => import("./routes/billing.js")
    },
    {
        id: "farms",
        parent: "with-tos",
        getChildren: () => import("./routes/farms.js")
    },
];

/**
 * This is a lost of all dependencies that are dinamically imported somewhere in the app.
 * Preload then on init, so we cant fail later because the file doesnt exist anymore
 * */
const depsPreload = [
    () => import("exceljs"),
    () => import("file-saver"),
    () => import("xlsx")
];

const appBasename = undefined;

/**
 *
 * @param {ReturnType<typeof UNSAFE_createBrowserHistory>} history
 * @param {import("react-router").DataRouteObject[]} routes
 * @returns
 */
function createHistoryRouter(history, routes) {
    // for now, all route that could be added to the context are eternal. in the future, we should handle cleanups too
    const context = {};
    const addedDynamicRoutes = {};

    return UNSAFE_createRouter({
        history, routes,
        mapRouteProperties: UNSAFE_mapRouteProperties,
        basename: appBasename,
        // inner function copied from https://reactrouter.com/en/main/routers/create-browser-router#middleware
        async dataStrategy({
            matches
        }) {
            // Run middleware sequentially and let them add data to `context`
            let promError = null;
            let hasRedirect = false;
            const promises = []; // the other loaders will be started in the same sequence, but wont be waited for

            const isRedirectResult = (res) => (
                res.result?.status && (res.result.status >= 300 && res.result.status < 400)
            );
            const customHandlerCall = (handler) => {
                // Whatever you pass to `handler` will be passed as the 2nd parameter
                // to your loader/action
                return handler(context);
            };
            // const nullHandlerCall = async (_ignored_handler) => {
            //     // This one will be used after we detect a redirect, so we dont get real data
            //     //const result = await handler(context);
            //     return null;
            // };

            const matchedToLoad = matches.filter(m => m.shouldLoad);
            for (const match of matchedToLoad) {
                let prom;
                if (hasRedirect) { // break early if we got a redirect
                    break;
                }

                (prom = match.resolve(/*hasRedirect ? nullHandlerCall : */customHandlerCall))
                    .then(res => {
                        if (isRedirectResult(res)) hasRedirect = true;
                    })
                    .catch(e => {
                        if (!promError) promError = e;
                    });
                promises.push(prom);

                // if (hasRedirect) { // break early if we got a redirect
                //     continue;
                // }

                // if we have addToWiseContext, await for these loaders first
                if (match.route.handle?.wiseWait) {
                    // and add them to the context
                    const res = (await prom) ?? {};
                    if (!isRedirectResult(res) && match.route.handle?.addToWiseContext) {
                        if (!match.route.id) {
                            console.log("Invalid, you need a route id for this");
                            continue;
                        }

                        try {
                            if (match.shouldLoad) {
                                context[match.route.id] = await res.result?.clone?.()?.json?.();
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }

                if (promError) throw promError;
            }

            // Run loaders in parallel with the `context` value
            return (await Promise.all(promises)).reduce((acc, result, i) =>
                Object.assign(acc, {
                    [matchedToLoad[i].route.id]: result,
                }),
            {}
            );
        },
        async patchRoutesOnNavigation({ matches, patch }) {
            let leafRouteId = matches[matches.length - 1]?.route?.id;
            if (!leafRouteId) return;
            // hack: this doesnt handle very well the route with no added path
            if (leafRouteId === "root") leafRouteId = "with-tos";

            // maybe in the future we could add a route parameter to our dynamicRoutes, so we dont add all of the routes
            const childrenToPatch = dynamicRoutes.filter(cr => cr.parent === leafRouteId);
            if (!childrenToPatch.length) return;

            childrenToPatch.forEach(cr => {
                const uniqueId = `${cr.parent}-${cr.id}`;
                if (addedDynamicRoutes[uniqueId]) {
                    // // We should never have this condition. Maybe iss not needed to thow an error, but be safe for now
                    // throw new Error("Route already added");
                }
                addedDynamicRoutes[uniqueId] = true;
            });

            const childenInfo = (await Promise.all(childrenToPatch.map(cr => cr.getChildren()))).map(r => r.default);
            patch(leafRouteId, Array.prototype.concat.call(...childenInfo));
        }
    }).initialize();
}

const getRouter = () => {
    const personalInfoRef = { current: null };

    const history = UNSAFE_createBrowserHistory({ window });
    const router = createHistoryRouter(history, [
        {
            path: "/",
            element: <Layout history={history} />,
            hydrateFallbackElement: <LoadingDialog />,
            // https://reactrouter.com/en/main/hooks/use-route-error
            errorElement: <ErrorElement name="RootRouteError" />,
            id: "root",
            handle: {
                wiseWait: true,
                addToWiseContext: true,
            },
            loader: async () => {
                // preload deps, but dont wait for them
                Promise.all(depsPreload.map(d => d())).then(() => console.debug("Deps Preloaded")).catch(e => console.error(e));

                const originalUrl = globalThis.sessionStorage.getItem("loginUrl");

                // if we do have a saved url from before the external login started, redirect to it
                if (originalUrl) {
                    globalThis.sessionStorage.removeItem("loginUrl"); // clean

                    const urlObject = new URL(originalUrl);
                    // const path = stripBasename(urlObject.pathname, appBasename || "");
                    /*if (path) */return replace(urlObject.pathname + urlObject.search + urlObject.hash);
                }

                const cache = personalInfoRef.current;
                delete personalInfoRef.current; // we are updating the info just because an action was called

                return Response.json(cache ?? await RestAPI.get("apiAdminDropControl", "/personal"));
            },
            action: async ({ request }) => {
                // all we are doing in this action, is save the modified data on a cache, so the next loader execution will get it
                return Response.json(personalInfoRef.current = await request.json());
            },
            shouldRevalidate: ({ formAction, formMethod, currentUrl, nextUrl }) => {
                if (currentUrl.pathname.includes("/tos") && nextUrl.pathname.includes("personal")) {
                    return true; // forced validate after ToS
                }
                return formAction === "/" && formMethod === "PATCH"; // this should only be revalidated on forced revalidations and the PATCH action
            },
            children: [
                {
                    path: "tos",
                    element: <PolicyAndTerms />,
                    loader: async (_, context) => {
                        if (context.root.politicas_aceptadas) {
                            return redirect("/personal");
                        }
                        return Response.json({});
                    },
                },
                {
                    element: <FarmComponent history={history} />,
                    hydrateFallbackElement: <LoadingDialog />,
                    errorElement: <ErrorElement name="TOSRouteError" />,
                    id: "with-tos",
                    handle: {
                        wiseWait: true, // so we do the redirect to '/tos' before executing other loaders
                    },
                    loader: async ({ request }, context) => {
                        // https://github.com/remix-run/react-router/issues/11129
                        if (!context.root.politicas_aceptadas) {
                            return redirect("/tos");
                        }

                        const farm = localStorage.getItem("farm_id_selected");
                        const url = new URL(request.url);
                        if (!url.search && farm && url.pathname === "/"){
                            const metadata = await RestAPI.get("apiAdminDropControl", `/farms/${farm}/metadata`);
                            if (metadata){
                                return redirect(`/farms?farm=${farm}`);
                            }
                        }
                        return Response.json({});
                    },
                    children: [
                        {
                            element: null,
                            id: "dummy",
                            index: true
                        },
                    ]
                },
            ]
        }
    ]);

    if (!router.navigate.hack) {
        const navigate_orig = router.navigate;
        const navigate = (...params) => {
            putLog(JSON.parse(JSON.stringify(params)));
            navigate_orig(...params);
        };
        navigate.hack = true;
        router.navigate = navigate;
    }
    return router;
};
const App = () => {
    // loader functions will be called inmediatly when createBrowserRouter is called, if the route is correct
    // to prevent failing because we have no auth-data, defer the router creation to the mounting of the app component
    const [routerInfo, setRouterInfo] = useState({ router: null, idx: 0 });

    useEffect(() => {
        const router = getRouter();
        setRouterInfo((ri) => ({ router, idx: (ri.idx + 1) }));

        // we need to clear the memory of the previous router
        return () => {
            router.dispose();
        };
    }, []);

    if (!routerInfo.router) return null;
    return <RouterProvider
        key={routerInfo.idx} router={routerInfo.router}
    />;
};

function Layout({ history }) {
    const revalidate = useRevalidator();
    const navigation = useNavigation();

    return <ErrorBoundary name="Root">
        {(navigation.state === "loading" || revalidate.state === "loading") && <LoadingDialog />}
        <ToastContainer closeOnClick icon={false} theme="colored" position="top-right" />
        <Suspense fallback={<LoadingDialog />}>
            <div className="control-section">
                <TypesProvider>
                    <FarmProvider>
                        <div>
                            <GATracker history={history} />
                            <Outlet />
                        </div>
                    </FarmProvider>
                </TypesProvider>
            </div>
        </Suspense>
    </ErrorBoundary>;
}

const FarmComponent = ({ history }) => {
    const usuarioInfo = useRouteLoaderData("root");

    const selectorRef = useRef(null);
    const mapRef = useRef(null);
    const [dataSource, setDataSource] = useState({ loading: false, data: null });
    const [fields, setFields] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(null);
    const historyRef = useRef(null);
    historyRef.current = history;

    const [error, setError] = useState(false);

    const { refreshUser, signOut, userInfo } = useContext(AuthenticatorContext);
    const [ds] = useContext(FarmContext);

    const isBilling = useMatch("/billing/*");

    const personalInfo = useMemo(() => {
        const newPersonalInfo = { ...usuarioInfo };

        if (userInfo && newPersonalInfo) {
            if (userInfo.name) {
                newPersonalInfo.nombre = userInfo.name;
            }
            if (userInfo.family_name
        && userInfo.family_name !== `${newPersonalInfo.apellido_paterno || ""} ${newPersonalInfo.apellido_materno || ""}`) {
                const idx = userInfo.family_name.indexOf(" ");
                const splits = idx > -1 ? [userInfo.family_name.slice(0, idx), userInfo.family_name.slice(idx + 1)]
                    : [userInfo.family_name, ""];
                newPersonalInfo.apellido_paterno = splits[0] || "";
                newPersonalInfo.apellido_materno = splits[1] || "";
            }
        }

        return newPersonalInfo;
    }, [usuarioInfo, userInfo]);

    return (<>
        <div id="wrapper" className={(ds?.dataFarm?.acceso_limitado ? "acceso_limitado " : "") + (!menuIsOpen ? "sidebar-closed" : "sidebar-open")}>
            <Header dataUser={personalInfo} userInfo={userInfo} signOut={signOut}
                toggleSidebar={() => setMenuIsOpen(c => !c)}
            />
            {
                isBilling ?
                    <BillingMenubar setMenuIsOpen={setMenuIsOpen} />
                    :
                    <Menubar historyRef={historyRef} innerRef={selectorRef} mapRef={mapRef} userInfo={userInfo}
                        fields={fields} setFields={setFields} dataSource={dataSource} setDataSource={setDataSource} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen}
                    />
            }
            <div id="sidebar-content">
                <ErrorBoundary name="Route" resetOnChange>
                    <Outlet context={{
                        personalInfo: personalInfo,
                        refreshUser: refreshUser,
                        usuarioInfo: usuarioInfo,
                        accountsData: dataSource.data,
                        fields: fields,
                        menuIsOpen: menuIsOpen,
                        mapRef: mapRef,
                        selectorRef: selectorRef,
                        error,
                        setError
                    }}
                    />
                </ErrorBoundary>
            </div>
        </div>
    </>);
};

export default withAuthenticator(App, Login, LoadingDialog);

// at least for now, preload all routes on application load
Promise.all(dynamicRoutes.map(cr => cr.getChildren())).catch(e => console.error(e));
